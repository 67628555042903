/* prettier-ignore */
.cursor-pointer {
  cursor: pointer;
}

.navbar {
  border-bottom: none;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
  /* initial color */
  transition: color 0.3s ease;
}

.navbar-mobile-logo {
  margin-right: 1.25rem;
}

/* Custom padding for logo on large screens due to bootstrap not having the spacing number required */
@media (min-width: 992px) {
  .custom-padding-lg {
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
  }
}
@media (min-width: 992px) {
  .navbar-dark .navbar-nav .nav-link {
    color: #0f2443;
  }

  .navbar-dark.transition-color .navbar-nav .nav-link {
    color: #0f2443;
  }
}
@media (max-width: 768px) {
  .left-align-mobile {
    text-align: left;
  }
}
.navbar-theme .navbar-nav > .nav-item .nav-link-text {
  transition: none;
}

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: transparent;
}

.navbar-dark .navbar-text {
  color: #ffffff;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  transition: background-image 0.3s ease;
}

.navbar-dark.transition-color .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2815, 36, 67, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-theme .navbar-nav > .nav-item > .nav-link:active > .nav-link-text,
.navbar-theme .navbar-nav > .nav-item > .nav-link.active > .nav-link-text,
.navbar-theme .navbar-nav > .nav-item > .nav-link:hover > .nav-link-text,
.navbar-theme .navbar-nav > .nav-item.show > .nav-link > .nav-link-text {
  background-color: #ffffff;
  color: #000;
  border-radius: 0.125rem;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffff;
}

.navbar-theme .navbar-nav > .nav-item > .nav-link:hover > .nav-link-text {
  border-radius: 0;
}

.navbar-toggler-icon {
  width: 28px;
  height: 28px;
  background-size: cover;
}

.main-title {
  color: #ffffff;
}

.sub-title {
  color: #ffffff;
}

.learn-more-button {
  border-radius: 0;
  color: #fff;
  border-color: #fff;
  background-color: rgba(15, 36, 67, 0.4);
  transition: all 0.15s;
  font-size: 0.8rem;
  font-weight: 400;
  position: relative;
  z-index: 10;
}

.learn-more-button:hover {
  color: #0b1a31;
  border-color: #fff;
  background-color: #fff;
}

.indicator-arrow {
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
}

.img-rounded {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

#director .col-md-6,
#management .col-md-6 {
  display: flex;
}

.sub-text {
  color: #d8d8d8;
}

.main-text {
  color: #fff;
}

.body-text {
  color: #d8d8d8;
}

.gold-text {
  color: #999;
}

.border-gold {
  border-color: #667b91 !important;
}

.border-gold-hover:hover {
  border: 1px solid #667b91 !important;
}

.bg-gold {
  background-color: #fff;
}

.bg-navy {
  background-color: #ffffff;
}

.nav-link {
  padding: 0.8rem 1rem;
}

.dropdown-menu {
  border-radius: 0;
}

.dropdown-item {
  border-radius: 0;
  color: #ffffff;
}

@media (max-width: 575.98px) {
  .dropdown-item {
    padding: 0.5rem 1rem;
  }
}
/* Adjust image cropping on mobile devices */
@media (max-width: 767px) {
  .crop-top-image {
    height: 400px;
    /* or whatever height you need */
    object-fit: cover;
    object-position: center 90%;
    /* Adjusts the vertical positioning */
  }
}
.bg-holder.overlay-2:before {
  background: #7b828b;
  background: radial-gradient(circle, rgba(15, 36, 67, 0.45) 57%, rgba(15, 36, 67, 0.6) 86%);
}

.bg-holder.overlay-0:before {
  background: rgba(15, 36, 67, 0.7);
}

:root,
:root.light,
:root .light {
  --reign-1100: #0b1a31;
}

.bg-sevenco-dark {
  background-color: #07111f;
}

.modal-open {
  overflow: inherit !important;
  padding-right: 0 !important;
}